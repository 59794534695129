/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { shape, string, func } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import hexToRgb from '../../../../helpers/contentstack/hexToRgb';
import { parseFont } from '../../../../helpers/common/helper';
import SeeDetailLink from '../SeeDetailLink/SeeDetailLink';
import StyledButton from '../../../EnterpriseDesignSystem/Button/StyledButton';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import CTA from '../../../ContentTypeComponents/Buttons/CTA';

const useStyles = makeStyles(() => ({
    mainContainer: (styleBlockData, isSmallerDevice, isLink) => ({
        minHeight: '48px',
        padding: '5px',
        alignItems: 'center',
        color: styleBlockData?.message_color?.color || '#2f2f2f',
        background: `radial-gradient( circle, ${hexToRgb(styleBlockData?.background_color?.color, '1')} 29%, ${hexToRgb(styleBlockData?.background_color?.color, '0')} 100%, ${styleBlockData?.background_color?.color}), ${styleBlockData?.background_image?.url ? `url("${styleBlockData?.background_image?.url}") center/18px` : ''}`,
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column wrap',
        flexDirection: 'unset',
        fontSize: `clamp(${styleBlockData?.min_font_size || 1.1}rem, 2.3vw, ${styleBlockData?.max_font_size || 1.8}rem)`,
        fontFamily: `${parseFont(styleBlockData?.message_font) || 'Crimson, Garamond, Times, serif'}`,
        backgroundColor: styleBlockData?.background_color?.color || '',
        backgroundSize: 'auto',
        '@media screen and (max-width: 768px)': {
            alignItems: 'right',
            minHeight: '40px',
            fontSize: `${styleBlockData?.min_font_size || 1.1}rem`,
        },
        '@media screen and (max-width: 600px)': {
            minHeight: isLink ? '4rem' : '2rem',
        },
    }),

    ctaButton: (styleBlockData) => ({
        '& div': {
            color: styleBlockData?.cta_color?.color || '#EBEBEB',
            fontFamily: styleBlockData?.styling?.cta_font || 'Lato',
            fontSize: '14px',
            background: 'none',
            cursor: 'pointer',
            textTransform: 'uppercase',
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '20px',
            letterSpacing: '0.56px',
            '&:hover': {
                backgroundColor: 'transparent',
                color: styleBlockData?.cta_color?.color || '#EBEBEB',
            },
            '&:focus': {
                outline: 'none',
            },
            '@media screen and (max-width: 768px)': {
                margin: '0',
            },
            '@media screen and (max-width: 600px)': {
                margin: '0 auto',
            },
        },

    }),
    bannerMessage: (styleBlockData) => ({
        textAlign: 'right',
        fontFamily: 'Area Variable, Lato',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '28px',
        letterSpacing: '0.2px',
        '& a': {
            color: styleBlockData?.highlighted_text_color?.color,
            textDecoration: 'none',
        },
        '& p': {
            margin: '0px 30px 0px 0px',
            fontSize: 'clamp (1rem, 2vw, 2rem) !important',
        },
        '@media screen and (max-width: 768px)': {
            textAlign: 'right',
            '& p': {
                margin: '0px 16px 0px 0px',
            },
        },
        '@media screen and (max-width: 600px)': {
            textAlign: 'center',
            fontFamily: 'Lato',
            fontSize: '16px',
            letterSpacing: '0.32px',
            lineHeight: '20px',
            paddingBottom: '4px',
        },
    }),
    siteWideBannerV2: {
        textAlign: 'center',
        minHeight: '48px',
        '@media screen and (max-width: 768px)': {
            minHeight: 40,
        },
    },
    siteWideBannerV2WithGrid: {
        textAlign: 'center',
        '@media screen and (max-width: 768px)': {
            minHeight: 40,
        },
    },
    seeDetailsLink: {
        '& u': {
            color: '#000000',
            textAlign: 'center',
            fontFamily: 'Lato',
            fontSize: '14px !important',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px', /* 142.857% */
            letterSpacing: '0.56px',
            textDecoration: 'underline',
        },
        '& div': {
            width: 'auto',
        },
    },
}));

const SiteWideBannerUI = ({ bannerData, clickHandler }) => {
    if (Object.keys(bannerData)?.length <= 0) return null;
    const ffIsEdsButtonEnabled = useSelector(getFeatureFlag('is-eds-button-enabled'));
    const isSmallerDevice = useMediaQuery('(max-width: 767px)');
    const styleBlockData = bannerData.styling;
    const promoCode = bannerData.promo_code_details;
    const isLink = bannerData.linking?.link?.href?.length || false;
    const buttonTitle = bannerData.linking?.link?.title || 'Shop Now';
    const buttonVariant = bannerData.button_variant || 'light';
    const classes = useStyles(styleBlockData, isSmallerDevice, isLink);
    const hasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const siteWideBannerV2CssStyle = hasHomepageGridStructure ? classes.siteWideBannerV2WithGrid : classes.siteWideBannerV2;
    const partnershipClass = () => {
        let partnership = false;
        if (bannerData.is_partnership_banner) partnership = true;
        return partnership;
    };
    return (
        <div className={`${siteWideBannerV2CssStyle}  ${partnershipClass() ? 'partnershipBanner' : ''}`}>
            <div data-testid="bannerv2-ui" className={classes.mainContainer} sm={12}>
                <div className={classes.bannerMessage}>
                    <ReactMarkdown source={bannerData?.message} escapeHtml={false} />
                </div>
                {isLink && bannerData?.has_cta && (
                    ffIsEdsButtonEnabled
                        ? <StyledButton label={buttonTitle} size="sm" variant={buttonVariant} isInsideClickableLink="true"  attributes={{ 'aria-labelledby': `site wide banner ${buttonTitle.split(' ').join(' ').toLowerCase()}` }} />
                        : (
                            <div className={classes.ctaButton}>
                                <CTA
                                    fontFamily={bannerData?.styling?.cta_font}
                                    inert
                                    disable_chevron
                                />
                            </div>
                        )
                )}
                {
                    bannerData?.see_details && promoCode && (
                        <SeeDetailLink promoCode={promoCode} detailLinkStyle={classes.seeDetailsLink} clickHandler={clickHandler} />
                    )
                }
            </div>
        </div>
    );
};
SiteWideBannerUI.defaultProps = {
    bannerData: shape({
        about_the_banner_message_: string,
        message: string,
        title: string,
        styling: shape({
            background_color: shape({
                color: string,
            }),
            cta_font: string,
        }),
    }),

};

SiteWideBannerUI.propTypes = {
    bannerData: {},
    clickHandler: func.isRequired,
};

export default SiteWideBannerUI;
